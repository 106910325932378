var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('InlineLoader',{staticClass:"inline-loader",attrs:{"loading":_vm.loading,"content":"Fetching your results…"}},[_c('v-data-table',{staticClass:"show-headers expandable-table",attrs:{"show-expand":"","must-sort":"","mobile-breakpoint":"1080","item-key":"checkupId","headers":_vm.headers,"items":_vm.completedChats,"no-data-text":_vm.tableMessage,"page":_vm.pageNumber,"sort-by":_vm.sortedCol,"sort-desc":_vm.sortDir === 'desc',"server-items-length":_vm.count,"items-per-page":25,"hide-default-footer":!_vm.completedChats.length,"expanded":_vm.expandedRows,"footer-props":{
        itemsPerPageOptions: [25],
        showCurrentPage: true,
        showFirstLastPage: true,
      }},on:{"update:sort-desc":_vm.onChangeSortDirection,"update:sort-by":_vm.onChangeSortedColumn,"update:page":_vm.updatePage,"item-expanded":_vm.onItemExpanded},scopedSlots:_vm._u([{key:"item.chatStatus",fn:function(ref){
      var item = ref.item;
return [_c('StatusIndicatorIcon',{attrs:{"status":item.chatStatus}})]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{staticClass:"chats-sub-table",attrs:{"colspan":headers.length}},[_c('InlineLoader',{staticClass:"inline-loader",attrs:{"loading":item.responsesLoading,"content":"Fetching Responses..."}},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.subTableHeaders,"items":item.responses,"items-per-page":-1,"no-data-text":item.responsesTableMessage},scopedSlots:_vm._u([{key:"item.responseStatus",fn:function(ref){
      var item = ref.item;
return [_c('StatusIndicatorIcon',{attrs:{"status":item.responseStatus}})]}},{key:"item.linkIcon",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":("/patients/" + (_vm.headerInfo.patientId) + "/responses")}},[_c('i',{staticClass:"material-icons-outlined icon",attrs:{"title":"View all responses to this question"},on:{"click":function($event){return _vm.viewChatResponses(item)}}},[_vm._v(" message ")])])]}}],null,true)})],1)],1)]}},{key:"item.data-table-expand",fn:function(ref){
      var expand = ref.expand;
      var isExpanded = ref.isExpanded;
return [_c('p',{staticClass:"action-column",on:{"click":function($event){return expand(!isExpanded)}}},[_c('span',{staticClass:"action-column__text"},[_vm._v(" "+_vm._s(isExpanded ? 'Show less' : 'Show more')+" ")]),(isExpanded)?_c('v-icon',[_vm._v("expand_less")]):_c('v-icon',[_vm._v("expand_more")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }